import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'
import WebsiteDownloadAppDialog from 'src/components/WebsiteDownloadAppDialog.vue'

@Component
export default class WebsiteFooter extends BaseMixin {
  @Prop({ type: Array }) readonly menuItems!: []

  showDownloadAppDialog () {
    this.$q.dialog({
      component: WebsiteDownloadAppDialog,
      parent: this
    })
  }
}
