import { render, staticRenderFns } from "./WebsiteFooter.vue?vue&type=template&id=a367023e"
import script from "./WebsiteFooter.ts?vue&type=script&lang=ts&external"
export * from "./WebsiteFooter.ts?vue&type=script&lang=ts&external"
import style0 from "./WebsiteFooter.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports