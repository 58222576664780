import { Component } from 'vue-property-decorator'
import { QDialog } from 'quasar'
import { BaseMixin } from 'src/components/mixins/base'
import IconClose from './svg-icons/IconClose.vue'
import IconApple from './svg-icons/IconApple.vue'
import IconAndroid from './svg-icons/IconAndroid.vue'
import IconAmazon from './svg-icons/IconAmazon.vue'

@Component({
  components: {
    IconClose,
    IconApple,
    IconAndroid,
    IconAmazon
  }
})
export default class WebsiteDownloadAppDialog extends BaseMixin {
  get dialog (): QDialog {
    return this.$refs.websiteDownloadAppDialog as QDialog
  }

  show () {
    this.dialog.show()
  }

  hide () {
    this.dialog.hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  cancel () {
    this.hide()
  }

  async confirm () {
    this.$emit('ok')
    this.hide()
  }

  mounted () {
  }
}
